import _rabin from "./rabin";
import _rabinWasmNode from "../dist/rabin-wasm.node.js";
var exports = {};
const Rabin = _rabin;
const getRabin = _rabinWasmNode;

const create = async (avg, min, max, windowSize, polynomial) => {
  const compiled = await getRabin();
  return new Rabin(compiled, avg, min, max, windowSize, polynomial);
};

exports = {
  Rabin,
  create
};
export default exports;
const _Rabin = exports.Rabin,
      _create = exports.create;
export { _Rabin as Rabin, _create as create };