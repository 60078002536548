var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

/**
 * Rabin fingerprinting
 *
 * @class Rabin
 */
class Rabin {
  /**
   * Creates an instance of Rabin.
   * @param { import("./../dist/rabin-wasm") } asModule
   * @param {number} [bits=12]
   * @param {number} [min=8 * 1024]
   * @param {number} [max=32 * 1024]
   * @param {number} polynomial
   * @memberof Rabin
   */
  constructor(asModule, bits = 12, min = 8 * 1024, max = 32 * 1024, windowSize = 64, polynomial) {
    (this || _global).bits = bits;
    (this || _global).min = min;
    (this || _global).max = max;
    (this || _global).asModule = asModule;
    (this || _global).rabin = new asModule.Rabin(bits, min, max, windowSize, polynomial);
    (this || _global).polynomial = polynomial;
  }
  /**
   * Fingerprints the buffer
   *
   * @param {Uint8Array} buf
   * @returns {Array<number>}
   * @memberof Rabin
   */


  fingerprint(buf) {
    const {
      __retain,
      __release,
      __allocArray,
      __getInt32Array,
      Int32Array_ID,
      Uint8Array_ID
    } = (this || _global).asModule;
    const lengths = new Int32Array(Math.ceil(buf.length / (this || _global).min));

    const lengthsPtr = __retain(__allocArray(Int32Array_ID, lengths));

    const pointer = __retain(__allocArray(Uint8Array_ID, buf));

    const out = (this || _global).rabin.fingerprint(pointer, lengthsPtr);

    const processed = __getInt32Array(out);

    __release(pointer);

    __release(lengthsPtr);

    const cleanArr = [];

    for (let i = 0; i < processed.length; i++) {
      if (processed[i] === 0) break;
      cleanArr[i] = processed[i];
    }

    return cleanArr;
  }

}

exports = Rabin;
export default exports;